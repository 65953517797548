<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>达人运营</a-breadcrumb-item>
    <a-breadcrumb-item>星图融合任务</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 提示 -->
  <!-- <a-alert :message="`今日充值数据整点更新，最新更新时间 ${refreshTime}`" type="warning" show-icon style="margin-top: 20px;" /> -->
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 日期范围： -->
    <span class="tool-title">日期范围：</span>
    <a-range-picker
      v-model:value="dateRangeModel"
      :format="dateFormat"
    />
    <!-- 任务类型： -->
    <span class="tool-title">任务类型：</span>
    <a-select v-model:value="taskTypeModel" style="width: 160px;" placeholder="请选择">
      <a-select-option :value="1">普通任务</a-select-option>
      <a-select-option :value="2">专属任务</a-select-option>
    </a-select>
    <!-- 合并数据 -->
    <span class="tool-title">合并数据：</span>
    <a-select v-model:value="mergeTypeModel" style="width: 120px;" placeholder="请选择">
      <a-select-option :value="0">否</a-select-option>
      <a-select-option :value="1">是</a-select-option>
    </a-select>
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="touchSearch">查询</a-button>
    <a-button style="margin-left: 16px; margin-right: 16px;" @click="touchReset">重置</a-button>
    <!-- 弹簧 -->
    <div style="flex: 1;"></div>
    <!-- 操作 -->
    <a-button @click="touchDownload">导出数据</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="false"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'total_settle_cps' || column.key === 'total_settle_ad_share' || column.key === 'total_settle'">
        {{ record[column.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 1000, 2, false, true) }}
      </template>
    </template>
  </a-table>
  <!-- 自定义分页器 -->
  <a-pagination
    v-if="dataSource.length"
    style="margin: 16px 0; text-align: right;"
    v-model:current="pagination.current"
    v-model:pageSize="pagination.pageSize"
    :total="pagination.total"
    :showSizeChanger="pagination.showSizeChanger"
    @change="handlePaginationChange"
  />
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import * as Ex from "@netjoy/excelex"
import Pub from '@/utils/public'
import dayjs from 'dayjs'
import { dyxtTaskData, dyAgentName } from '@/api/copyright'

// 更新时间
let refreshTime = ref('')
// 加载
let isLoading = ref(false)
// 任务类型
let taskTypeModel = ref(undefined)
let taskType = ref(undefined)
// 日期范围
const dateFormat = 'YYYY-MM-DD'
let dateRangeModel = ref([dayjs().subtract(32, 'day'), dayjs().subtract(2, 'day')])
let dateRange = ref([dayjs().subtract(32, 'day'), dayjs().subtract(2, 'day')])
// 合并数据
let mergeTypeModel = ref(0)
let mergeType = ref(0)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '日期',
    dataIndex: 'data_date',
    key: 'data_date'
  },
  {
    title: '任务名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '任务ID',
    dataIndex: 'task_id',
    key: 'task_id'
  },
  {
    title: '充值分成',
    dataIndex: 'total_settle_cps',
    key: 'total_settle_cps'
  },
  {
    title: '广告分成',
    dataIndex: 'total_settle_ad_share',
    key: 'total_settle_ad_share'
  },
  {
    title: '总计分成',
    dataIndex: 'total_settle',
    key: 'total_settle'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 初始化选择项列表
  getSelectList()
  // 获取推广数据列表
  getList()
})

// 模糊匹配
function filterOption (input, option) {
  console.log(option)
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 分页处理
function handlePaginationChange (current, pageSize) {
  pagination.current = current
  pagination.pageSize = pageSize
  getList()
}

// 查询
function touchSearch () {
  // 调整参数
  taskType.value = taskTypeModel.value
  dateRange.value = dateRangeModel.value
  mergeType.value = mergeTypeModel.value
  pagination.current = 1
  // 获取列表
  getList()
}

// 重置
function touchReset () {
  dateRangeModel.value = [dayjs().subtract(32, 'day'), dayjs().subtract(2, 'day')]
  mergeTypeModel.value = 0
  mergeType.value = 0
  taskTypeModel.value = undefined
  taskType.value = undefined
  // 初始化获取
  touchSearch()
}

// 获取推广数据列表
function getList () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    type: taskType.value,
    is_merge: mergeType.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  dyxtTaskData(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data.concat([data.sumarry])
      pagination.total = data.total
      refreshTime.value = data.refresh_time
    } else {
      message.error(res.message || msg)
    }
  })
}

// 初始化选择项列表
function getSelectList () {
  // dyAgentName().then(res => {
  //   const { code, data } = res
  //   if (code === 0) {
  //     termediarys.value = data
  //   }
  // })
}

// 点击导出数据
function touchDownload () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    type: taskType.value,
    is_merge: mergeType.value,
    page: 1,
    page_size: 100000
  }
  dyxtTaskData(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      // 将汇总数据插入第一条
      const list = [].concat(data.data).concat([data.sumarry])
      if (list.length) {
        const columns = [
          {
            name: '日期',
            field: 'data_date'
          },
          {
            name: '任务名称',
            field: 'name'
          },
          {
            name: '任务ID',
            field: 'task_id'
          },
          {
            name: '充值分成',
            field: 'total_settle_cps'
          },
          {
            name: '广告分成',
            field: 'total_settle_ad_share'
          },
          {
            name: '总计分成',
            field: 'total_settle'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          // row=0 为表头
          if (row != 0) {
            // 金额处理
            if (field === 'total_settle_cps' || field === 'total_settle_ad_share' || field === 'total_settle') {
              if (item.data != '-') {
                item.data = Pub.KEEP_NUMBER_DECIMAL(item.data / 1000, 2, false, true)
              }
              return item
            }
          }
          // 无数据兼容
          // item.data = item.data || 0
          return item
        },  `达人运营-星图融合任务`)
      } else {
        message.error('还没有可导出的内容')
      }
    } else {
      message.error('导出失败')
    }
  }).catch(() => {
    message.error('导出失败')
  })
}
</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>